import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Spinner from "../ui-elements/Spinner"
import Button from '../formfields/Button'
import Text from '../formfields-new/Text'
import RedAlert from '../ui-elements/RedAlert'
import Modal from '../ui-elements/Modal'
import { useParams } from 'react-router-dom'
import Password from '../formfields-new/Password'

type Props = {
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>
  pin?: string
}


function GuestUserLoginForm({
  setShowForm,
  pin
}: Props) {

  const {
    tableData,
    setShowModal,
    sendMessageToWebsocket,
    currentOrganisation,
    orgIdFromQrCode
  } = useContext(GlobalContext)

  const { checklistId } = useParams()
  const initialFormData = { "UserName": { "value": '', "required": true, "type": "text" } }
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
  const [errorMessage, setErrorMessage] = useState<string>('')


  const validate = () => {
    let isValid = true
    let errorMessage = ''
    if (
      !formFields["UserName"]['value'] ||
      formFields["UserName"]['value'].trim() === ''
    ) {
      isValid = false
      errorMessage = `${errorMessage} Please enter your name. `
    }

    if (pin) {
      if (!formFields['pin'] || !formFields['pin'].value) {
        isValid = false
        errorMessage = `${errorMessage} Please enter a PIN. `
      }
    }
    setErrorMessage(errorMessage)
    return isValid
  }


  const validateAndSave = () => {
    const isValid = validate()
    if (isValid) {
      setErrorMessage('')
      const payload: ObjectStringKeyAnyValue = {
        action: 'guestLogin',
        guestLoginAction: 'guestLoginRequestFromLoginForm',
        "UserName": `${formFields['UserName'].value}`,
        "OrganisationId": `${orgIdFromQrCode ? orgIdFromQrCode : currentOrganisation}`
      }
      if (formFields && formFields['pin'] && formFields['pin'].value) {
        payload['pin'] = formFields['pin'].value
      }
      sendMessageToWebsocket(JSON.stringify(payload))
    }
  }



  return <div className={`w-full flex flex-col gap-4`}>

    {/* <h3 className={`font-righteous text-2xl`}>Log in</h3> */}

    <div className={`flex flex-col gap-2`}>
      <p>What is your full name?</p>
      <Text
        labelText={''}
        formFields={formFields}
        setFormFields={setFormFields}
        fieldName={'UserName'}
        placeholder={''}
        errorMessage={'Please enter your full name'}
        size={'big'}
        center={true}
        value={formFields && formFields['UserName'] && formFields['UserName'].value || ''}
      />
    </div>


    {pin && <div className={`flex flex-col gap-2`}>
      <p>Organisation PIN:</p>
      <Password
        labelText={''}
        formFields={formFields}
        setFormFields={setFormFields}
        fieldName={'pin'}
        placeholder={''}
        errorMessage={'Please enter your name'}
        size={'big'}
        center={true}
        value={formFields && formFields['pin'] && formFields['pin'].value || ''}
      />
    </div>}


    {errorMessage && <RedAlert>{errorMessage}</RedAlert>}

    <div className={`w-full flex flex-row gap-4`}>
      <Button
        onClick={() => setShowForm(false)}
        text={'Cancel'}
        variant={'gray'}
        size={'big'}
        sameWindow={true}
      />
      <Button
        onClick={validateAndSave}
        text={'Continue'}
        variant={'secondary'}
        size={'big'}
        sameWindow={true}
      />
    </div>
  </div>
}
export default GuestUserLoginForm