import React, { useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import RedAlert from '../ui-elements/RedAlert'
import { updateObjectKeyAndValue } from '../utils/updateObjectKeyAndValue'

type Props = {
  formFields: ObjectStringKeyAnyValue
  setFormFields: React.Dispatch<React.SetStateAction<any>>
  labelText: string
  fieldName: string,
  errorMessage?: string
  size?: 'big' | 'small',
  center?: boolean
  placeholder?: string
  value: string | number | boolean
}

function Password({
  formFields,
  setFormFields,
  labelText,
  fieldName,
  errorMessage,
  size,
  center,
  placeholder,
  value
}: Props) {


  const classString = `
  bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full
  ${center ? 'text-center' : 'text-left'}
  ${size === 'big' && 'text-lg px-5 py-3'}
  ${size === 'small' && 'text-xs px-2 py-1'}
  ${size !== 'small' && size !== 'big' && 'text-sm px-3 py-2'}
  `

  return <div className={`${center ? 'text-center items-center' : 'text-left  items-start'} w-full flex flex-col gap-2`}>
    {labelText && <p className={`${center ? 'text-center' : 'text-left'}`}>{labelText}</p>}
    <input
      className={classString}
      type='password'
      placeholder={placeholder}
      name={fieldName}
      id={fieldName}
      // value={formFields[fieldName] ? formFields[fieldName]['value'] : ''}
      value={`${value}`}
      onChange={(e) => updateObjectKeyAndValue(formFields, setFormFields, fieldName, e.target.value)}
    />
    {formFields[fieldName] && formFields[fieldName]['isValid'] === false &&
      <RedAlert
        alignment={center ? 'center' : 'left'}
        size={'small'}
      >
        {errorMessage ? errorMessage : 'Please enter a value'}
      </RedAlert>}
  </div>
}
export default Password