import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Spinner from "../ui-elements/Spinner"
import Button from '../formfields/Button'
import Text from '../formfields-new/Text'
import RedAlert from '../ui-elements/RedAlert'
import Modal from '../ui-elements/Modal'
import { useNavigate, useParams } from 'react-router-dom'
import GuestUserLoginForm from './GuestUserLoginForm'

type Props = {
  loginUrl: string
  loginOptions: null | ObjectStringKeyAnyValue
}


function NotLoggedIn({
  loginUrl,
  loginOptions
}: Props) {

  const {
    tableData,
    sendMessageToWebsocket,
    currentOrganisation,
    orgIdFromQrCode
  } = useContext(GlobalContext)

  const { checklistId } = useParams()
  const navigate = useNavigate()
  const [showForm, setShowForm] = useState(false)
  const [deleteLocalStorage, setDeleteLocalStorage] = useState(false)





  useEffect(() => {
    const mismatchedChecklistId = localStorage.getItem('mismatchedChecklistId')
    mismatchedChecklistId && checkForChecklistIdInLocalStorage(mismatchedChecklistId)
  }, [])


  useEffect(() => {
    if (deleteLocalStorage) {
      localStorage.removeItem('mismatchedChecklistId')
    }
  }, [deleteLocalStorage])


  const checkForChecklistIdInLocalStorage = (mismatchedChecklistId: string) => {
    //console.log('💟')
    const windowpath = window.location.pathname
    if (windowpath === "/" && mismatchedChecklistId) {
      //console.log('🚨 Found mismatched checklist id in local storage')
      navigate(`/start-checklist/${mismatchedChecklistId}`)
      setDeleteLocalStorage(true)
    } else {
      //console.log('🪼')
    }
  }



  // console.log(loginOptions)

  const in_iframe = window.parent.location !== window.location


  return <div className={`bg-brandblue flex flex-col min-h-screen items-center justify-between text-center gap-4 p-4`}>

    <div className={`flex-1 flex flex-col justify-center items-center`}>


      <div className={`w-64 flex flex-col gap-6 items-center justify-center text-white`}>
        <img
          src={`/logos/logo-white-no-strapline.svg`}
          alt={'ChecQR logo'}
        />

        {!showForm && <div className={`w-full flex flex-col gap-4`}>
          <p>Welcome to ChecQr!</p>

          {checklistId && !loginOptions && <Spinner>Checking login options...</Spinner>}

          {loginOptions && !loginOptions['Email'] && !loginOptions['Guest'] && <RedAlert>No login options set up for this organisation</RedAlert>}




          {(!checklistId || (loginOptions && loginOptions['Email'])) && <Button
            onClick={(event: Event) => {
              event.preventDefault()
              if (in_iframe) {
                window.parent.location.href = loginUrl
              } else {
                window.location.href = loginUrl
              }
            }}
            text={'Log in with email'}
            variant={'secondary'}
            size={'big'}
            sameWindow={true}
          />}

          {loginOptions && loginOptions['Guest'] && <Button
            onClick={() => setShowForm(true)}
            text={loginOptions['Guest']['pin'] ? `Log in with PIN` : 'Guest log in'}
            variant={'secondary'}
            size={'big'}
            sameWindow={true}
          />}
        </div>}


        {showForm && loginOptions && loginOptions['Guest'] && <GuestUserLoginForm
          setShowForm={setShowForm}
          pin={loginOptions['Guest']['pin']}
        />}


      </div>

    </div>

    <a href='https://checqr.net/what-is-checqr-about' className={`mb-4 text-sm text-white underline hover:opacity-80`}>What is ChecQR all about?</a>

  </div>
}
export default NotLoggedIn