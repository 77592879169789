import React, { useState, useEffect, useRef } from 'react'
import { GlobalContext } from './GlobalContext'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"

// utilities
import CloudformationOutputs from './CloudformationOutputs.json'
import { checkIfSubscriptionExpiringWithoutPayment } from './utils/checkIfSubscriptionExpiringWithoutPayment'
import { publish } from './utils/pubsub'
// UI Elements
import Footer from './ui-elements/Footer'
import Header from './ui-elements/Header'
// shared components
import AppTermsDialog from './shared-components/AppTermsDialog'
import SubscriptionExpired from './shared-components/SubscriptionExpired'
import SubscriptionExpiring from './shared-components/SubscriptionExpiring'
import NotLoggedIn from './shared-components/NotLoggedIn'
import Dashboard from './dashboard/Dashboard'
import ChecklistQuestions from './dashboard/ChecklistQuestions'
import QuestionPage from './question-pages/QuestionPage'
import VideoProvider from './context/video/VideoProvider'
import VideoBackground from './shared-components/VideoBackground'
import ScanQrCode from './question-pages/ScanQrCode'
import StartAdhocChecklist from './dashboard/StartChecklist'
import CompleteChecklist from './dashboard/CompleteChecklist'
import AddPhotoNote from './question-pages/AddPhotoNote'
import AllChecklists from './dashboard/AllChecklists'
import OrgMismatchDialog from './shared-components/OrgMismatchDialog'
import AddAnswerToQuestion from './question-pages/AddAnswerToQuestion'
import MessageModal from './ui-elements/MessageModal'


type Organisations = null | ObjectStringKeyAnyValue

function App() {

  const [loggedIn, setLoggedIn] = useState<boolean | string>(false)
  const [idToken, setIdToken] = useState<string | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [connectionState, setConnectionState] = useState('disconnected')
  const [orgSettings, setOrgSettings] = useState('disconnected')
  const [userData, setUserData] = useState<ObjectStringKeyAnyValue | null>(null)
  const [currentOrganisation, setCurrentOrganisation] = useState<string | null>(null)
  const [orgIdFromQrCode, setOrgIdFromQrCode] = useState<string | null>(null)
  const loginUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/login?client_id=${CloudformationOutputs.UserPoolClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${window.location.origin}/dashboard/`
  const logoutUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/logout?client_id=${CloudformationOutputs.UserPoolClientId}&logout_uri=${window.location.origin}/`
  const WebSocketURI = CloudformationOutputs.WebSocketURI
  const [tableData, setTableData] = useState<ObjectStringKeyAnyValue | null>(null)
  const [loggedInUserRole, setLoggedInUserRole] = useState<string | null>(null)
  const tableDataRef = useRef<any>(null)
  const [activeFeatures, setActiveFeatures] = useState<{ [key: string]: any }>({})
  const [expiredFeatures, setExpiredFeatures] = useState<{ [key: string]: any }>({})
  const [isAccountSetup, setIsAccountSetup] = useState<boolean | null>(null)
  const [agreementNeeded, setAgreementNeeded] = useState<{ [key: string]: any } | null>(null)
  const [subscriptionHasBeenChecked, setSubscriptionHasBeenChecked] = useState<boolean>(false)
  const [subscriptionExpired, setSubscriptionExpired] = useState<boolean>(false)
  const [subscriptionExpiringWithoutPayment, setSubscriptionExpiringWithoutPayment] = useState<boolean>(false)
  const [checkPeriods, setCheckPeriods] = useState<ObjectStringKeyAnyValue | null>(null)
  const [activeChecklistIds, setActiveChecklistIds] = useState<Array<string>>([])
  const [inActiveChecklistIds, setInActiveChecklistIds] = useState<Array<string>>([])
  const [checkPeriodIdsForActiveCheckists, setCheckPeriodIdsForActiveChecklists] = useState<any>({})
  const [isVideoMode, setIsVideoMode] = useState<boolean>(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)
  const [isInPhoneMode, setIsInPhoneMode] = useState(true)
  const [loginOptions, setLoginOptions] = useState<null | ObjectStringKeyAnyValue>(null)
  const [isSignoffRequired, setIsSignoffRequired] = useState(false)
  const [orgMismatchDialog, setOrgMismatchDialog] = useState<string | null>(null)
  const [showModal, setShowModal] = useState<ObjectStringKeyAnyValue | null>(null)
  const orgIdFromQrCodeRef = useRef(orgIdFromQrCode)
  const loginOptionsRef = useRef(loginOptions)

  // console.log(tableData)
  // tableData && tableData.ChecklistQuestions && console.log(JSON.stringify(tableData.ChecklistQuestions))

  // To do - postpone this until after we're sure we're not about to be sent to a cognito login screen


  useEffect(() => {
    checkForToken()
    setConnectionState('tryToConnect')
  }, [])


  useEffect(() => {
    orgIdFromQrCodeRef.current = orgIdFromQrCode
  }, [orgIdFromQrCode])

  useEffect(() => {
    loginOptionsRef.current = loginOptions
  }, [loginOptions])



  useEffect(() => {
    switch (connectionState) {
      case 'connected':
        findLoginsPermittedForChecklistId()
        checkForGuestConnection()
        break
      case 'closed':
      case 'error':
        setConnectionState('waitThenReconnect')
        setTimeout(() => {
          setConnectionState('tryToConnect')
        }, 200)
        break;
      case 'tryToConnect':
        setConnectionState('connecting')
        const newSocket = new WebSocket(WebSocketURI)
        newSocket.onopen = () => {
          setSocket(newSocket)
          setConnectionState('connected')
        }
        newSocket.onclose = () => {
          console.log("🔌  socket closed")
          setConnectionState('closed')
        }
        newSocket.onerror = () => {
          setConnectionState('error')
          console.log("🔌 socket error")
        }
        break
    }
  }, [connectionState])

  useEffect(() => {
    if (
      tableData && tableData.Organisation &&
      tableData.Organisation.ChecklistOptions &&
      tableData.Organisation.ChecklistOptions.RequireSignoff === true
    ) {
      setIsSignoffRequired(true)
    } else {
      setIsSignoffRequired(false)
    }

  }, [tableData])


  useEffect(() => {
    if (tableData) {
      checkIfSubscriptionExpiringWithoutPayment(tableData, subscriptionHasBeenChecked, setSubscriptionExpiringWithoutPayment, setSubscriptionHasBeenChecked)
      // set logged in user role
      tableDataRef.current = tableData
      if (tableData.Users && userData) {
        const loggedInUserId = userData['cognito:username']
        const role = tableData.Users[loggedInUserId] ? tableData.Users[loggedInUserId].Role : null
        setLoggedInUserRole(role)
      }
    }
  }, [tableData, userData])

  useEffect(() => {
    // handle incoming messages
    if (socket !== null) {
      actOnMessage()
    }
  }, [socket])

  useEffect(() => {
    if (idToken && accessToken && socket) {
      // sending tokens to websocket!
      const payloadObj: { [index: string]: string } = {
        action: "login",
        idToken: idToken,
        accessToken: accessToken
      }
      const selectedOrgId = localStorage.getItem("selectedOrgId")
      if (selectedOrgId) {
        payloadObj['selectedOrgId'] = selectedOrgId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }
  }, [idToken, accessToken, socket, loggedIn, currentOrganisation])


  const findLoginsPermittedForChecklistId = () => {
    const pathName = window.location.pathname
    const pathNameArray = pathName.split('/')
    const url_params = new URLSearchParams(window.location.search)
    const asset_id = url_params.get('assetId')
    if (
      pathNameArray.length >= 3 &&
      (pathNameArray[1] === 'checklists' || pathNameArray[1] === 'start-checklist')
    ) {
      const checklistIdFromPath = pathNameArray[2]
      //console.log(`checking login options for checklist id ${checklistIdFromPath}`)
      const payloadObj: { [index: string]: string } = {
        action: "guestLogin",
        guestLoginAction: "findLoginsPermittedForChecklistId",
        checklistId: `${checklistIdFromPath}`
      }
      //console.log(payloadObj)
      if (asset_id) {
        payloadObj['asset_id'] = asset_id
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    } else {
      // console.log('🦺')
    }
  }


  const checkForGuestConnection = () => {
    const localGuestLogin = localStorage.getItem('guestLogin')
    if (localGuestLogin) {
      console.log('👤 guest login details found in local storage')
      const payload = JSON.parse(localGuestLogin)
      payload['action'] = "guestLogin"
      payload['guestLoginAction'] = "guestLoginRequestFromLocalStorage"
      sendMessageToWebsocket(JSON.stringify(payload))
    } else {
      //console.log('⛔️ no guest login details found in local storage')
    }
  }


  const redirectAfterLogin = () => {
    const lastVisitedPage = localStorage.getItem('lastVistedPage')
    if (lastVisitedPage) {
      console.log(`➡️ redirecting to ${lastVisitedPage}`)
      localStorage.removeItem('lastVistedPage')
      window.location.href = lastVisitedPage
    }
  }

  // const sortActivechecklists = () => {
  //   if (tableData && tableData.ChecklistPeriods) {
  //     const newCheckPeriods: ObjectStringKeyAnyValue = {}
  //     for (const checkPeriodId in tableData.ChecklistPeriods) {
  //       const checkPeriod = tableData.ChecklistPeriods[checkPeriodId]
  //       const isInProgress = checkPeriod.InProgress
  //       const checklistId = `${checkPeriod.ChecklistId}`
  //       const thisChecklist = tableData.Checklists[checklistId]
  //       // if the check period has an active checklist associated with it
  //       if (thisChecklist && thisChecklist.isChecklistActive !== false) {
  //         newCheckPeriods[checkPeriodId] = checkPeriod
  //         newCheckPeriods[checkPeriodId].Checklist = thisChecklist
  //       }
  //     }
  //     setCheckPeriods(newCheckPeriods)
  //   }
  // }

  const checkForToken = () => {
    //console.log('checking for a token')
    const localIdToken = localStorage.getItem("localIdToken")
    const localAccessToken = localStorage.getItem("localAccessToken")
    const queryStringParams = window.location.hash.replace("#", "").split(/[&=]+/)

    let idToken = null
    let accessToken = null

    const indexOfIdToken = queryStringParams.indexOf('id_token')
    if (indexOfIdToken >= 0) {
      //console.log("Got id token in URL line")
      idToken = queryStringParams[indexOfIdToken + 1]
    }
    const indexOfAccessToken = queryStringParams.indexOf('access_token')
    if (indexOfAccessToken >= 0) {
      //console.log("Got access token in URL line")
      accessToken = queryStringParams[indexOfAccessToken + 1]
    }

    if (idToken && accessToken) {
      //console.log('login pending')
      //console.log("Got new access tokens")

      if (localStorage.getItem('simulatedPhoneLogin') === 'true') {
        window.location.href = '/phonemode' + window.location.search
      } else {
        //console.log("Not a phone mode login")
      }
      setLoggedIn('pending')
      setIdToken(idToken)
      setAccessToken(accessToken)
      localStorage.setItem('localIdToken', idToken)
      localStorage.setItem('localAccessToken', accessToken)
      redirectAfterLogin()

    } else if (localIdToken && localAccessToken) {
      //console.log('logged in earlier')
      setIdToken(localIdToken)
      setAccessToken(localAccessToken)
    } else {
      //console.log('not logged in')
      if (window.location.pathname !== '/') {
        localStorage.setItem('lastVistedPage', window.location.href)
      }
    }
  }

  const switchCurrentOrganisation = (organisationId: string, redirect = true) => {
    console.log(`Organization id change requested from ${currentOrganisation} to ${organisationId}`)
    localStorage.setItem("selectedOrgId", organisationId)
    setCurrentOrganisation(organisationId)
    setTableData(null)
    if (idToken && accessToken && socket) {
      const payloadObj: { [index: string]: string } = {
        action: "login",
        selectedOrgId: organisationId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }
    if (redirect) {
      window.location.href = '/'
    }
  }

  const sendMessageToWebsocket = async (payload: string) => {
    if (socket) {
      // console.log(`sending to websocket: ${JSON.parse(payload).action}`)
      // console.log(`sending to websocket: ${payload}`)
      socket.send(payload)
    } else {
      console.log('🚫  Can not send to web socket')
    }
  }


  function checkPaymentDetailsAndRedirect(redirectUrl: string) {
    const lastPaymentTimestamp = localStorage.getItem('lastPaymentRequestTimestamp');
    const currentTime = Date.now();
    // Check if the timestamp exists and is less than 2 minutes ago
    if (lastPaymentTimestamp) {
      const timeElapsed = currentTime - parseInt(lastPaymentTimestamp, 10);

      // If less than 10 minutes have passed, do nothing
      if (timeElapsed < 2 * 60 * 1000) {
        console.log('Payment details were requested less than 2 minutes ago. No redirect.');
        return;
      }
    }
    // If the timestamp doesn't exist or more than 2 minutes have passed, update the timestamp and redirect
    window.location.href = redirectUrl;
  }


  const mergeTableData = (newData: ObjectStringKeyAnyValue) => {
    const oldTableData = tableDataRef.current
    if (oldTableData) {
      let mergedTableData = { ...oldTableData };
      Object.keys(newData).forEach(key => {
        // Directly assigning the value from newData, overriding the oldData value if key exists in both
        mergedTableData[key] = newData[key];
      });
      setTableData(mergedTableData)
    } else {
      setTableData(newData)
    }
  }


  const fetchData = () => {
    let requestAssets = JSON.stringify({
      action: "checklistsClientApp",
      subAction: "fetchMultiTableData"
    })
    sendMessageToWebsocket(requestAssets)
  }

  const actOnMessage = () => {
    if (socket && socket.onmessage === null) {
      socket.onmessage = ({ data }: any) => {
        if (data) {
          const action = JSON.parse(data).action
          const payload = JSON.parse(data).payload
          publish(action, payload)
          //console.log(`📨 A message has been received: ${action}`)
          switch (action) {
            case 'error':
              console.log(`🚫  Error: ${payload && JSON.stringify(payload)}`)
              break

            case 'autherror':
              console.log('🚫  Authentication error - logging you out')
              window.location.href = logoutUrl
              break

            case 'loggedIn':
              // console.log("🪵 logged in")
              const loginDataBody = JSON.parse(data).body
              //console.log(loginDataBody)
              setLoggedIn(true)
              const rawUserData = loginDataBody.idTokenDecoded.payload
              setUserData(loginDataBody.idTokenDecoded.payload)
              setOrgSettings(loginDataBody.settings)
              setActiveFeatures(loginDataBody['activeFeatures'] || {})
              setExpiredFeatures(loginDataBody['expiredFeatures'] || {})

              const loggedInOrgId = loginDataBody.organisationId

              //console.log(`🐙 loggin in id: ${loggedInOrgId}  id from qr code: ${orgIdFromQrCodeRef.current}`)

              if (loggedInOrgId) {
                if (orgIdFromQrCodeRef.current && orgIdFromQrCodeRef.current !== loggedInOrgId) {
                  const allowedOrgIdsForThisUser = Object.keys(JSON.parse(rawUserData.organisationsForUser))
                  if (allowedOrgIdsForThisUser.includes(orgIdFromQrCodeRef.current)) {
                    console.log('✅ possible to switch to this org as the same user')
                    setOrgMismatchDialog('switchOrgAsSameUser')
                  } else {
                    if (loginOptionsRef.current && loginOptionsRef.current['Checklists'] && loginOptionsRef.current['Checklists'].includes('guest')) {
                      console.log('👤 possible to switch to this org as a guest user')
                      setOrgMismatchDialog('switchOrgAsGuest')
                    } else {
                      console.log('⛔️ not possible to switch to this org at all')
                      setOrgMismatchDialog('loginAsDifferentUser')
                    }
                  }
                }
                setCurrentOrganisation(loggedInOrgId)
                localStorage.setItem('selectedOrgId', loggedInOrgId)

              }
              fetchData()
              break

            case 'refuseGuestConnection':
              console.log('⛔️ guest login refused')
              if (payload.showError) {
                setShowModal({ "error": ` ${payload.showError}` })
              }
              if (payload.showLog) {
                console.log(payload.showLog)
              }
              localStorage.removeItem('guestLogin')
              break

            case 'confirmGuestConnection':
              console.log('✅ guest login sucessful')
              setLoggedIn('guest')
              payload.OrganisationId && switchCurrentOrganisation(payload.OrganisationId, false)
              localStorage.setItem('guestLogin', JSON.stringify(payload))
              const userData = {
                "name": payload.UserName,
                organisationsForUser: JSON.stringify({
                  [payload.OrganisationId]: payload.OrganisationName
                })
              }
              setUserData(userData)
              fetchData()
              break

            case 'loginOptionsPermitted':
              // console.log(`✅ login options permitted: ${JSON.stringify(payload)}`)
              payload['organisationId'] && setOrgIdFromQrCode(payload['organisationId'])
              payload['loginOptions'] && setLoginOptions(payload['loginOptions'])

              break

            case 'loginFailed':
              //window.location.href = loginUrl
              localStorage.removeItem('guestLogin')
              localStorage.removeItem('localIdToken')
              localStorage.removeItem('localAccessToken')
              window.location.reload() 
              break

            case 'loginOKButNoOrganisation':
              window.localStorage.setItem('loggedInNoOrgId', data)
              window.location.href = '/noorg'
              break

            case 'returnMultiTableData':
              //console.log(`❎ Multiple tables received`)
              //console.log(`❎ Multiple tables received: ${JSON.stringify(payload)}`)
              mergeTableData(payload)
              break

            case 'returnSingleTableData':
              const newKey = Object.keys(payload)[0]
              const newValue = payload[newKey]
              const newTableData = { ...tableDataRef.current }
              newTableData[newKey] = newValue
              setTableData(newTableData)
              //console.log(`❎  updated table received: ${Object.keys(payload)[0]} at time: ${Date.now()}`)
              break

            case 'tableUpdateItemAdded':
            case 'tableUpdateItemModified':
              const tableToUpdate = payload['tableName']
              const itemToUpdate = payload['keys'][Object.keys(payload['keys'])[0]]
              // console.log(`❎  updated item received: ${tableToUpdate}`) 
              // console.log(payload)
              const updatedTableData = { ...tableDataRef.current }
              updatedTableData[tableToUpdate] ||= {}
              updatedTableData[tableToUpdate][itemToUpdate] = payload['newRecord']
              setTableData(updatedTableData)
              break
            case 'tableUpdateItemRemoved':
              const tableToRemoveItemFrom = payload['tableName']
              const itemToRemoveKey = payload['keys'][Object.keys(payload['keys'])[0]]
              const tableDataWithItemRemoved = { ...tableDataRef.current }
              if (tableDataWithItemRemoved[tableToRemoveItemFrom] && tableDataWithItemRemoved[tableToRemoveItemFrom][itemToRemoveKey]) {
                delete tableDataWithItemRemoved[tableToRemoveItemFrom][itemToRemoveKey]
              }
              setTableData(tableDataWithItemRemoved)
              break

            case 'ok':
              //console.log('super admin command ok')
              break

            case 'agreementNeeded':
              setAgreementNeeded(payload)
              break;
            case 'agreementSaved':
              console.log(payload)
              setAgreementNeeded(null)
              break;

            case 'addUserResponse':
            case 'deleteUserResponse':
            case 'switchUserRoleResponse':
              //console.log(`❎  user table update: ${JSON.stringify(payload)}`)
              const newUserKey = Object.keys(payload)[0]
              const newUserValue = payload[newUserKey]
              const newUserData = { ...tableDataRef.current }
              newUserData[newUserKey] = newUserValue
              setTableData(newUserData)
              break
            case 'paymentMethodNeeded':
              checkPaymentDetailsAndRedirect(payload['portalUrl'])
              break

            case 'returnPresignedUrl':
            case 'resetAnswerResponse':
              // handled when pub sub or when table data is updated
              break

            default:
              //console.log(`A message has been received but the action is invalid. ${data}`)
              break
          }
        }
      }
    }
  }


  if (window.location.pathname === '/phonemode' || window.location.pathname === '/phonemode/') {
    localStorage.setItem('simulatedPhoneLogin', 'true')
    return <div className={`w-screen h-screen flex flex-col items-center justify-center`}>

      <div style={{
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
        backgroundColor: '#000000',
        color: '#000000',
        width: '360px',
      }}>.</div>
      <iframe
        src={`${window.location.origin}`}
        style={{
          border: '10px solid black',
          width: '360px',
          height: '600px'
        }} />

      <div style={{
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px',
        backgroundColor: '#000000',
        color: '#000000',
        width: '360px',
      }}>.</div>
      <a href='#' onClick={(event: any) => {
        event.preventDefault()
        localStorage.removeItem('simulatedPhoneLogin')
        window.location.href = '/'
      }}>Leave phone mode</a>
    </div>
  }



  const handleLogout = () => {
    localStorage.setItem('lastVistedPage', window.location.href)
    setLoggedIn(false)
    setUserData(null)
    localStorage.removeItem('guestLogin')
    localStorage.removeItem('localIdToken')
    localStorage.removeItem('localAccessToken')
    window.location.href = logoutUrl
  }




  if (loggedIn) {
    return <div className={`flex flex-col bg-gradient-to-b from-tealgreen to-tealblue`}>
      <GlobalContext.Provider value={{
        tableData,
        setShowModal,
        userData,
        currentOrganisation,
        isSignoffRequired,
        switchCurrentOrganisation,
        sendMessageToWebsocket,
        isVideoMode,
        setIsVideoMode,
        isVideoPlaying,
        setIsVideoPlaying,
        isInPhoneMode,
        setIsInPhoneMode,
        loggedIn,
        checkPeriodIdsForActiveCheckists,
        loginOptions,
        orgIdFromQrCode,
        handleLogout
      }}>
        <VideoProvider>
          {subscriptionExpired && <SubscriptionExpired />}
          {subscriptionExpiringWithoutPayment && <SubscriptionExpiring
            setSubscriptionExpiringWithoutPayment={setSubscriptionExpiringWithoutPayment}
          />}
          <Router>


            <VideoBackground>



              {showModal && <MessageModal showModal={showModal} />}


              <Header
                loggedIn={loggedIn}
                logoutUrl={logoutUrl}
                setLoggedIn={setLoggedIn}
                setUserData={setUserData}
              />

              <div
                className={`z-0`}
                style={{ padding: '60px 0 80px 0' }}
              >


                {agreementNeeded && <AppTermsDialog
                  details={agreementNeeded}
                  sendMessageToWebsocket={sendMessageToWebsocket}
                />}
                <Routes>

                  <Route path='/*'
                    element={<Dashboard />} />

                  <Route path='/checklists'
                    element={<AllChecklists />} />

                  <Route path='/start-checklist/:checklistId'
                    element={<StartAdhocChecklist
                      orgMismatchDialog={orgMismatchDialog}
                      setOrgMismatchDialog={setOrgMismatchDialog}
                    />} />

                  <Route path='/checklists/:checkPeriodId'
                    element={<ChecklistQuestions />} />

                  <Route path='/scan-qr-code'
                    element={<ScanQrCode />} />

                  <Route path='/checklists/:checkPeriodId/scan-qr-code'
                    element={<ScanQrCode />} />

                  <Route path='/checklists/:checkPeriodId/complete-checklist'
                    element={<CompleteChecklist />} />

                  <Route path='/checklists/:answerId/add-photo-note'
                    element={<AddPhotoNote />} />

                  <Route path='/checklists/:checkPeriodId/add-answer/:questionId'
                    element={<AddAnswerToQuestion />} />

                  <Route path='/checklists/:checkPeriodId/:answerId'
                    element={<QuestionPage showAnswerConfirmation={false} />} />

                  <Route path='/checklists/:checkPeriodId/confirm/:answerId'
                    element={<QuestionPage showAnswerConfirmation={true} />} />



                </Routes>
              </div>
            </VideoBackground>
          </Router>
        </VideoProvider>
      </GlobalContext.Provider>

    </div>
  } else {
    return <GlobalContext.Provider value={{
      tableData,
      setShowModal,
      userData,
      currentOrganisation,
      switchCurrentOrganisation,
      sendMessageToWebsocket,
      orgIdFromQrCode
    }}>


      {showModal && <MessageModal showModal={showModal} />}
      <Router>
        <Routes>

          <Route path='/start-checklist/:checklistId'
            element={<NotLoggedIn
              loginUrl={loginUrl}
              loginOptions={loginOptions}
            />} />

          <Route path='/checklists/:checkPeriodId'
            element={<NotLoggedIn
              loginUrl={loginUrl}
              loginOptions={loginOptions}
            />} />

          <Route path='*'
            element={<NotLoggedIn
              loginUrl={loginUrl}
              loginOptions={loginOptions}
            />} />

        </Routes>
      </Router>
    </GlobalContext.Provider>
  }
}

export default App
